import { getSupportedCountries } from '../js/countryCodeUtil';
import { getFirebaseConfig } from '../js/firebaseStg';
import versionData from 'cacheVersion.json';

export const environment = {
  production: false,
  isEU: true,
  okta: {
    clientId: '0oa3d9872mbA6QEY80x7',
    issuer: 'https://resmed-airview-ps-eu.oktapreview.com/oauth2/aus3dca9lbJaW9bxP0x7',
    redirectUri: 'https://myair-web-qa.rmdeu.live',
    scopes: ['openid', 'profile', 'email'],
    baseUrl: 'https://resmed-airview-ps-eu.oktapreview.com',
  },
  endpoints: {
    staticMyair: 'https://assets.qa.myair-shared.rmdeu.live',
    localMetadataMyAir: `assets/${versionData.jsonVersion}/config/web-config.json`,
  },
  app_info: {
    app_version: '1.0.0',
    app_name: 'myAir EU',
  },
  firebase: getFirebaseConfig(true),
  supportedCountries: getSupportedCountries(),
  myAirLink: {
    EU: 'https://myair-web-qa.rmdeu.live',
    NonEU: 'https://myair-web-qa.dht.live',
  },
  appOnlyCountries: ['KR', 'SG', 'IN', 'HK', 'MY', 'PH'],
  oneTrustIdEU: '5c6eaa3a-c49b-4fe1-8fa9-98bd3f87b085',
  oneTrustIdAMR: '17caadfe-bcc4-48c9-8c69-2b99622a6e34'
};
