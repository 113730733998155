import { Injectable } from '@angular/core';
import { UrlTree, Router } from '@angular/router';
import { AuthService } from '@auth/auth.service';
import { PatientService } from '../patient/patient.service';
import { HeadersService } from '../showtime-api/headers.service';
import { SpinnerService } from '../spinner/spinner.service';

@Injectable({
  providedIn: 'root',
})
export class PatientHasDeviceGuard  {
  constructor(
    private router: Router,
    private patientService: PatientService,
    private spinnerService: SpinnerService,
    private headersService: HeadersService,
    private authService: AuthService
  ) {}
  canLoad(): Promise<boolean | UrlTree> | boolean {
    return this.canActivate();
  }
  async canActivate(): Promise<boolean | UrlTree> {
    // Ensure the patient is authenticated and has the expected list of headers
    // before checking if they have a device assigned.
    if (!await this.authService.checkAuthenticated() || !this.headersService.isHeaderItemsAvailable()) {
      return false;
    }
    const spinner = this.spinnerService.show();
    return this.patientService
      .getDevices()
      .toPromise()
      .then(([devices]) => {
        spinner.hide();
        if (devices.fgDevices) {
          return this.router.createUrlTree(['dashboard']);
        }
        return true;
      })
      .catch(() => {
        // can activate register-device on network error
        // the error message will be shown as an alert
        spinner.hide();
        return true;
      });
  }
}
