<section id="section1" class="o-section">
  <div class="o-wrapper">
    <div class="o-layout">
      <div class="o-layout__item  [ u-10/12@desktop u-push-1/12@desktop ]">

        <h1 class="c-heading-page">{{ 'DEVICE-REGISTRATION.MyEquipment' | translate}}</h1>

        <div class="c-form--has-validation  [ u-11/12@tablet u-7/12@desktop ]">
          <div class="c-alert c-alert--warning c-alert--small show-icon">
            <div class="c-alert__body">{{ 'DEVICE-REGISTRATION.DontHaveMachineAssociated' | translate}}</div>
          </div>
        </div>

        <mpp-add-device [deviceFormGroup]="equipmentFormGroup.get('device')"></mpp-add-device>

        <mpp-add-mask [maskFormGroup]="equipmentFormGroup.get('mask')"></mpp-add-mask>

        @if (isEU) {
          <div class="[ u-11/12@tablet u-7/12@desktop ]">
            <div class="c-form-field">
              <div class="c-form-field__input">
                <div class="c-input c-input--checkbox align-top">
                  <input [formControl]="legallyAuthorized" type="checkbox" id="legally-authorized">
                  <label class="c-form-field__label" for="legally-authorized">{{ 'MY-ACCOUNT/MY-EQUIPMENT.LegallyAuthorizedAgree' | translate }}</label>
                </div>
              </div>
            </div>
          </div>
        }

        <div class="c-form-buttons  [ o-layout o-layout--auto ]">
          <div class="o-layout__item">
            <button class="c-btn c-btn--primary" validationgroup="SaveEquipment"
              (click)="onSubmit()" [disabled]="equipmentFormGroup.invalid || legallyAuthorized.invalid">{{ 'DEVICE-REGISTRATION.Save' | translate}}</button>
          </div>
          <div class="o-layout__item">
            <a class="c-btn c-btn--tertiary" (click)="onCancel()">{{ 'DEVICE-REGISTRATION.Cancel' | translate}}</a>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>
